import 'common/polyfills';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import { Theme, CssBaseline, FallbackPlaceholder, Router } from 'components';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Theme>
    <CssBaseline />
    <Suspense fallback={<FallbackPlaceholder />}>
      <Router>
        <App />
      </Router>
    </Suspense>
  </Theme>
);

/// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
