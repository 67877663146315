/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import MaterialIconButton from '@mui/material/IconButton';

function IconButton({
  classes, className, IconComponent, ...props
}) {
  return (
    <MaterialIconButton
      className={className}
      {...props}
    >
      <IconComponent
        classes={{
          ...classes.icon && { root: classes.icon }
        }}
      />
    </MaterialIconButton>
  );
}

IconButton.propTypes = {
  IconComponent: PropTypes.elementType.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string
};

IconButton.defaultProps = {
  className: '',
  classes: {}
};

export default IconButton;
