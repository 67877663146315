import { useRoutes } from 'react-router-dom';
import React, { lazy } from 'react';
import { FallbackPlaceholder } from './components';

const AppLayout = lazy(() => import('containers/App'));
const Home = lazy(() => import('containers/home'));

const CampaignsHome = lazy(() => import('containers/campaigns/Home'));
const CampaignsLayout = lazy(() => import('containers/campaigns/Campaigns'));
const Search = lazy(() => import('containers/campaigns/Search'));
const CampaignsView = lazy(() => import('containers/campaigns/Search/CampaignsView'));
const Favorites = lazy(() => import('containers/campaigns/Favorites'));
// const FilterView = lazy(() => import('containers/campaigns/Search/FilterView'));
const CategoryView = lazy(() => import('containers/campaigns/Search/CategoryView'));
const Campaign = lazy(() => import('containers/campaigns/Campaign'));

const SignUpView = lazy(() => import('containers/signup/SignUpPage'));
const ConfirmEmail = lazy(() => import('containers/login/ConfirmEmail'));
const Social = lazy(() => import('containers/login/Social'));
const ResetPassword = lazy(() => import('containers/login/ResetPassword'));
const Membership = lazy(() => import('containers/content/Membership'));
const FiltersGuide = lazy(() => import('containers/content/FiltersGuide'));
const HeroJourneyGuide = lazy(() => import('containers/content/HeroJourneyGuide'));
const Consultancy = lazy(() => import('containers/content/Consultancy'));

const Generator = lazy(() => import('containers/generator'));
const Start = lazy(() => import('containers/generator/Start'));
const Projects = lazy(() => import('containers/generator/Projects'));
const Ideas = lazy(() => import('./containers/generator/Projects/Ideas'));
const FavoriteIdeas = lazy(() => import('./containers/generator/Favorites'));

const AdminLayout = lazy(() => import('containers/administrator'));
const AdminCampaigns = lazy(() => import('containers/administrator/Campaigns'));

const AdminGeneratorLayout = lazy(() => import('containers/administrator/Generator/Layout'));
const AdminGeneratorIdeas = lazy(() => import('containers/administrator/Generator/Idea'));
const AdminGeneratorUsers = lazy(() => import('containers/administrator/Generator/Users'));
const AdminGeneratorFilters = lazy(() => import('containers/administrator/Generator/Filters'));
const AdminGeneratorInputFields = lazy(() => import('containers/administrator/Generator/InputFields'));
const AdminGeneratorFeedback = lazy(() => import('containers/administrator/Generator/Feedback'));

const Empty = lazy(() => import('containers/empty'));

function App() {
  const routes = useRoutes([
    { path: '/',
      element: (
        <React.Suspense fallback={<FallbackPlaceholder />}>
          <AppLayout />
        </React.Suspense>
      ),
      children: [
        { index: true,
          element: (
            <React.Suspense fallback={<FallbackPlaceholder />}>
              <CampaignsHome />
            </React.Suspense>
          )
        },
        { path: 'campaigns',
          element: (
            <React.Suspense fallback={<FallbackPlaceholder />}>
              <CampaignsLayout />
            </React.Suspense>
          ),
          children: [
            { index: true,
              element: (
                <Search
                  moreButtonLabel={'search.moreButtonLabel'}
                  moreButtonMessage={'campaigns:search.moreButtonMessage'}
                />
              )
            },
            { path: 'favorites',
              element: <Favorites />
            },
            // { path: 'filter/:parentFilter',
            //   element: <FilterView />
            // },
            { path: 'category/:parentCategory',
              element: <CategoryView />
            },
            { path: 'navigate/:navigationKey',
              element: <CampaignsView />
            },
            { path: ':campaignName/:campaignKey',
              element: <Campaign />
            },
            { path: ':campaignKey',
              element: <Campaign />
            }
          ]
        },
        {
          path: 'generator',
          element: (
            <React.Suspense fallback={<FallbackPlaceholder />}>
              <Generator />
            </React.Suspense>
          ),
          children: [
            { index: true,
              element: (
                <React.Suspense fallback={<FallbackPlaceholder />}>
                  <Start />
                </React.Suspense>
              )
            },
            { path: 'start',
              element: (
                <React.Suspense fallback={<FallbackPlaceholder />}>
                  <Start />
                </React.Suspense>
              )
            },
            {
              path: 'projects',
              element: (
                <React.Suspense fallback={<FallbackPlaceholder />}>
                  <Projects />
                </React.Suspense>
              )
            },
            {
              path: 'projects/:projectId',
              element: (
                <React.Suspense fallback={<FallbackPlaceholder />}>
                  <Ideas />
                </React.Suspense>
              )
            },
            {
              path: 'favorites',
              element: (
                <React.Suspense fallback={<FallbackPlaceholder />}>
                  <FavoriteIdeas />
                </React.Suspense>
              )
            }
          ]
        },
        {
          path: 'signup',
          element: <SignUpView />
        },
        {
          path: 'signup/:step',
          element: <SignUpView />
        },
        {
          path: 'signup/:step/select/:priceId',
          element: <SignUpView />
        },
        {
          path: 'auth/confirmEmail',
          element: <ConfirmEmail />
        },
        {
          path: 'auth/social/:redirectPath',
          element: <Social />,
        },
        {
          path: 'auth/resetPassword',
          element: <ResetPassword />
        },
        {
          path: 'about',
          element: <Home />
        },
        {
          path: 'consultancy',
          element: <Consultancy />
        },
        {
          path: 'membership',
          element: <Membership />
        },
        {
          path: 'bitw-filter-guidance',
          element: <FiltersGuide />
        },
        {
          path: 'the-hero-journey-guide-to-creativity',
          element: <HeroJourneyGuide />
        },
        {
          path: 'admin',
          element: (
            <React.Suspense fallback={<FallbackPlaceholder />}>
              <AdminLayout />
            </React.Suspense>
          ),
          children: [
            { index: true,
              element: (
                <React.Suspense fallback={<FallbackPlaceholder />}>
                  <AdminCampaigns />
                </React.Suspense>
              )
            },
            {
              path: 'generator',
              element: (
                <React.Suspense fallback={<FallbackPlaceholder />}>
                  <AdminGeneratorLayout />
                </React.Suspense>
              ),
              children: [
                { index: true,
                  element: (
                    <React.Suspense fallback={<FallbackPlaceholder />}>
                      <AdminGeneratorIdeas />
                    </React.Suspense>
                  )
                },
                { path: 'filters',
                  element: (
                    <React.Suspense fallback={<FallbackPlaceholder />}>
                      <AdminGeneratorFilters />
                    </React.Suspense>
                  )
                },
                { path: 'fields',
                  element: (
                    <React.Suspense fallback={<FallbackPlaceholder />}>
                      <AdminGeneratorInputFields />
                    </React.Suspense>
                  )
                },
                { path: 'users',
                  element: (
                    <React.Suspense fallback={<FallbackPlaceholder />}>
                      <AdminGeneratorUsers />
                    </React.Suspense>
                  )
                },
                { path: 'feedback',
                  element: (
                    <React.Suspense fallback={<FallbackPlaceholder />}>
                      <AdminGeneratorFeedback />
                    </React.Suspense>
                  )
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '*',
      element: (
        <React.Suspense fallback={<FallbackPlaceholder />}>
          <Empty />
        </React.Suspense>
      )
    }
  ]);
  return (
    routes
  );
}

export default App;
