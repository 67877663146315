import { lazy } from 'react';

export { default as Theme } from './Theme';
export { default as IconButton } from './IconButton';
export { default as Autocomplete } from '@mui/material/Autocomplete';
export { default as MaterialIconButton } from '@mui/material/IconButton';
export { default as FallbackPlaceholder } from './FallbackPlaceholder';
export { default as FormControl } from '@mui/material/FormControl';
export { default as FormControlLabel } from '@mui/material/FormControlLabel';
export { default as TextField } from '@mui/material/TextField';
export { default as InputAdornment } from '@mui/material/InputAdornment';
export { default as Button } from '@mui/material/Button';
export { default as ProgressButton } from './ProgressButton';
export { default as Paper } from '@mui/material/Paper';
export { default as Typography } from '@mui/material/Typography';
export { default as Container } from '@mui/material/Container';
export { default as AppBar } from '@mui/material/AppBar';
export { default as Toolbar } from '@mui/material/Toolbar';
export { default as Grid } from '@mui/material/Grid';
export { default as Box } from '@mui/material/Box';
export { default as Avatar } from '@mui/material/Avatar';
export { default as Popover } from '@mui/material/Popover';
export { default as CircularProgress } from '@mui/material/CircularProgress';
export { default as CssBaseline } from '@mui/material/CssBaseline';
export { default as Drawer } from '@mui/material/Drawer';
export { default as Checkbox } from '@mui/material/Checkbox';
export { default as Divider } from '@mui/material/Divider';
export { default as Badge } from '@mui/material/Badge';
export { default as BottomNavigation } from '@mui/material/BottomNavigation';
export { default as BottomNavigationAction } from '@mui/material/BottomNavigationAction';
export { default as Skeleton } from '@mui/material/Skeleton';
export { default as MobileStepper } from '@mui/material/MobileStepper';
// export { default as Stack } from '@mui/material/Stack';
export { default as Breadcrumbs } from '@mui/material//Breadcrumbs';

export { makeStyles, withStyles } from '@mui/styles';

export { useTheme } from '@mui/material/styles';
export { default as useMediaQuery } from '@mui/material/useMediaQuery';
export { debounce } from '@mui/material';
export { styled } from '@mui/material/styles';

export { NavLink, useNavigate, BrowserRouter as Router } from 'react-router-dom';
export { default as LazyLoad } from 'react-lazyload';

export { default as Link } from '@mui/material/Link';
export const Menu = lazy(() => import('@mui/material/Menu'));
export const MenuItem = lazy(() => import('@mui/material/MenuItem'));
export const Stack = lazy(() => import('@mui/material/Stack'));
export const Tab = lazy(() => import('@mui/material/Tab'));
export const TabContext = lazy(() => import('@mui/lab/TabContext'));
export const TabList = lazy(() => import('@mui/lab/TabList'));
export const TabPanel = lazy(() => import('@mui/lab/TabPanel'));
export const ExpansionPanel = lazy(() => import('@mui/material/Accordion'));
export const ExpansionPanelSummary = lazy(() => import('@mui/material/AccordionSummary'));
export const ExpansionPanelDetails = lazy(() => import('@mui/material/AccordionDetails'));
export const List = lazy(() => import('@mui/material/List'));
export const ListItem = lazy(() => import('@mui/material/ListItem'));
export const ListItemText = lazy(() => import('@mui/material/ListItemText'));
export const OutlinedInput = lazy(() => import('@mui/material/OutlinedInput'));
export const Image = lazy(() => import('./Image'));
export const Snackbar = lazy(() => import('./Snackbar'));
export const Notification = lazy(() => import('./Snackbar/Notification'));
export const ModalDialog = lazy(() => import('./ModalDialog'));
export const Video = lazy(() => import('./Video'));
export const Chip = lazy(() => import('@mui/material/Chip'));
export const Switch = lazy(() => import('@mui/material/Switch'));
export const Tooltip = lazy(() => import('@mui/material/Tooltip'));
export const Select = lazy(() => import('./Select'));
export const MultiSelect = lazy(() => import('./MultiSelect'));
export const GroupMultiSelect = lazy(() => import('./MultiSelect/Group/GroupMultiSelect'));
export const DataGrid = lazy(() => import('./DataGrid'));

export const Card = lazy(() => import('@mui/material/Card'));
export const CardMedia = lazy(() => import('@mui/material/CardMedia'));
export const CardActions = lazy(() => import('@mui/material/CardActions'));
export const CardContent = lazy(() => import('@mui/material/CardContent'));
export const UploadFile = lazy(() => import('./UploadFile'));
export const Rating = lazy(() => import('@mui/material/Rating'));
