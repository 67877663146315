import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';

function ProgressButton({ isLoading, ...props }) {
  return <LoadingButton loading={isLoading} {...props} />
}

ProgressButton.propTypes = {
  isLoading: PropTypes.bool,
};

ProgressButton.defaultProps = {
  isLoading: false,
};

export default ProgressButton;
