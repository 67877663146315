import React from 'react';
import PropTypes from 'prop-types';
import * as colors from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';

const theme = createTheme({
  palette: {
    primary: {
      ...colors.deepOrange,
      contrastText: colors.common.white
    },
    secondary: colors.blueGrey,
    error: colors.red,
    blue: colors.blue,
    green: colors.green,
    purple: colors.purple,
    deepOrange: colors.deepOrange
  },
  typography: {
    fontFamily: 'Poppins, Roboto'
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          zIndex: 100
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
          textTransform: 'none !important',
        },
        sizeLarge: {
          fontSize: 18,
          fontWeight: 'bold'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          '&$checked': {
            color: colors.deepOrange[500]
          }
        }
      }
    },

    MuiSwitch: {
      styleOverrides: {
        colorSecondary: {
          '&$checked': {
            color: colors.deepOrange[500]
          }
        }
      }
    },

    MuiRadio: {
      styleOverrides: {
        colorSecondary: {
          '&$checked': {
            color: colors.deepOrange[500]
          }
        }
      }
    },

    MuiChip: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          fontWeight: 'bold'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '4px'
        }
      }
    }
  }
});

const globalStyles = {
  '*': {
    '&:focus': {
      outline: 'none'
    },
    '&:active': {
      outline: 'none'
    }
  },
  ol: {
    paddingLeft: '1.1em'
  },
  a: {
    color: colors.deepOrange[600],
    textDecoration: 'none',
    transition: 'opacity .15s linear',
    '&:hover': {
      opacity: '.75',
      textDecoration: 'none'
    }
  },
  small: {
    lineHeight: '1.2'
  },
  body: {
    overflow: 'hidden',
    overflowY: 'auto',
    backgroundColor: '#F7F8F9 !important'
  }
};

function Theme({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={globalStyles} />
      {children}
    </ThemeProvider>
  );
}

Theme.propTypes = {
  children: PropTypes.any
};

Theme.defaultProps = {
  children: null
};

export default Theme;
